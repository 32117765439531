import checkoutLoyalty from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/checkout/loyalty"
import componentsBopis from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/bopis"
import componentsCartOrderSummary from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/cart/orderSummary"
import componentsCheckoutFooter from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/checkout/footer"
import componentsCheckoutOrderItem from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/checkout/orderItem"
import componentsCheckoutShippingAndDelivery from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/checkout/shippingAndDelivery"
import componentsCmsBannerOverlay from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/cms/bannerOverlay"
import componentsCmsBannerThin from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/cms/bannerThin"
import componentsCmsBlockBelow from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/cms/blockBelow"
import componentsCmsImageBanner from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/cms/imageBanner"
import componentsCmsLinkedCard from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/cms/linkedCard"
import componentsCmsProductRecommendations from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/cms/productRecommendations"
import componentsDashboardCard from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/dashboard/card"
import componentsDialogMiniCart from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/dialog/miniCart"
import componentsFormInterests from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/form/interests"
import componentsFormSignUp from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/form/signUp"
import componentsLoyaltyBenefits from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/loyalty/benefits"
import componentsOrderDetailsItem from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/order/detailsItem"
import componentsProductAccordions from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/product/accordions"
import componentsProductCard from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/product/card"
import componentsProductUpsell from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/product/upsell"
import componentsVfDialog from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/vf/dialog"
import componentsVfFooter from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/vf/footer"
import componentsVfLoyalty from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/components/vf/loyalty"
import pagesAccount from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/pages/account"
import pagesCart from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/pages/cart"
import pagesCheckout from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/pages/checkout"
import pagesHelp from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/pages/help"
import pagesOrderStatus from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/pages/orderStatus"
import pagesPdp from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/pages/pdp"
import pagesProfile from "/opt/build/repo/brands/thenorthface/regions/nora/config/app/pages/profile"
    
export default {"checkout":{"loyalty":checkoutLoyalty},"components":{"bopis":componentsBopis,"cart":{"orderSummary":componentsCartOrderSummary},"checkout":{"footer":componentsCheckoutFooter,"orderItem":componentsCheckoutOrderItem,"shippingAndDelivery":componentsCheckoutShippingAndDelivery},"cms":{"bannerOverlay":componentsCmsBannerOverlay,"bannerThin":componentsCmsBannerThin,"blockBelow":componentsCmsBlockBelow,"imageBanner":componentsCmsImageBanner,"linkedCard":componentsCmsLinkedCard,"productRecommendations":componentsCmsProductRecommendations},"dashboard":{"card":componentsDashboardCard},"dialog":{"miniCart":componentsDialogMiniCart},"form":{"interests":componentsFormInterests,"signUp":componentsFormSignUp},"loyalty":{"benefits":componentsLoyaltyBenefits},"order":{"detailsItem":componentsOrderDetailsItem},"product":{"accordions":componentsProductAccordions,"card":componentsProductCard,"upsell":componentsProductUpsell},"vf":{"dialog":componentsVfDialog,"footer":componentsVfFooter,"loyalty":componentsVfLoyalty}},"pages":{"account":pagesAccount,"cart":pagesCart,"checkout":pagesCheckout,"help":pagesHelp,"orderStatus":pagesOrderStatus,"pdp":pagesPdp,"profile":pagesProfile}}
