import type { RecursivePartial } from '#types/utils'
import type { HelpConfig } from '#types/config/pages/help'

export default {
  brandClasses: {
    layout: 'my-6 flex pb-3 <lg:flex-col-reverse lg:gap-15',
    sidebar: 'w-65 shrink-0 print:hidden <lg:mt-5',
    tiles: 'grid gap-2 lg:cols-3'
  },
  showEmployeeContactUs: true,
  hasFaqPage: false
}satisfies RecursivePartial<HelpConfig>
