import type { CartConfig } from '#types/config/pages/cart'
import type { RecursivePartial } from '#types/utils'

export default {
  coupon: {
    isAccordionOpen: true
  },
  cartItem: {
    thumbnails: {
      width: { sm: 128, lg: 128 },
      height: { sm: 149, lg: 149 }
    }
  },
  showSlaCopy: true,
} satisfies RecursivePartial<CartConfig>
